/**
 * module rules.js
 */

export default {
  required: val => !!val || 'Campo obbligatorio',
  requiredIf: (model, name, msg = null) => {
    return val => {
      if (!model[name]) return true
      return !!val || msg || `Obbligatorio se ${name} è compilato`
    }
  },
  requiredIfNot: (model, name, msg = null) => {
    return val => {
      if (model[name]) return true
      return !!val || msg || `Obbligatorio se ${name} NON è compilato`
    }
  },
  requiredConditional: (funct) => {
    return val => {
      if (funct()) return true
      return !!val || 'Campo obbligatorio'
    }
  },
  email: val => val ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(val).toLowerCase()) || 'E-Mail non valida' : true,
  password: val => {
    if (!val || val.length === 0) return true
    const errori = [
      val.length >= 10 || 'La password deve essere lunga almeno 10 caratteri',
      /[A-Z]/.test(val) || 'La password deve contenere almeno una lettera MAIUSCOLA',
      /[a-z]/.test(val) || 'La password deve contenere almeno una lettera minuscola',
      /[0-9]/.test(val) || 'La password deve contenere almeno un carattere numerico',
      /[!#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(val) ||
        'La password deve contenere almeno un carattere speciale'
    ].filter(v => v !== true)
    return errori.length ? errori[0] : true
  },
  sequenza (model, name, times = 3) {
    return val => {
      if (!val || !val.length) return true
      const ref = model[name]
      for (let pos = 0; pos < val.length; pos++) {
        const part = val.substr(pos, times)
        if (part.length === times && ref.includes(part)) {
          return `ripetizione di "${part}" in "${val}" per ${ref}`
        }
      }
      return true
    }
  },
  sameAs (model, name, label = null, msg = null) {
    return val => (!val || !val.length) ? true : val === model[name] || msg || `Non corrisponde a ${label || name}`
  },
  phoneNumber (val) {
    if (!val || !val.length) return true
    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(val) || 'Numero non valido'
  },
  phoneOrMail (val) {
    let res = null
    if (val) {
      res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(val).toLowerCase().trim())
      if (!res) {
        res = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(val.trim())
      }
    }
    return res || 'Email o numero di telefono non valido'
  },
  numeric: val => (val && !isNaN(val)) || 'Deve essere un numero',
  minLenght (minLenght) {
    return val => (val && val.length < minLenght) ? `Lunghezza minima ${minLenght} caratteri` : true
  }
}
