<template>
  <v-card
    v-if="showMe"
    :flat="showBox"
  >
    <v-card-title
      v-if="showBox"
      class="text-subtitle-1"
    >
      Associazione account
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-if="showVCode"
        v-model="login.vcode"
        label="Codice convalida"
        name="validation_code"
        prepend-icon="mdi-file-code"
        type="text"
        :rules="[v => { return codeMandatory ? !!v || 'Codice validazione obbligatorio' : true }]"
        :disabled="bloccatoCode"
      />
      <v-select
        :items="appList"
        ref="selectedApp"
        item-text="name"
        item-value="id"
        label="Seleziona applicazione"
        :rules="[v => { return appMandatory ? !!v.name || 'Applicazione obbligatoria' : true }]"
        return-object
        v-model="app"
        :disabled="bloccatoApp"
      >
        <template #prepend>
          <v-img
            height="40px"
            max-width="40px"
            contain
            :src="app.logo || ''"
          />
        </template>
        <template #item="{ item }">
          <v-list-item-avatar>
            <v-img
              height="40px"
              max-width="40px"
              contain
              :src="item.logo || ''"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
          </v-list-item-content>
        </template>
      </v-select>
      <template v-if="app.id">
        <v-text-field
          v-model="login.username"
          label="App Login"
          name="app_login"
          prepend-icon="mdi-account"
          type="text"
          :disabled="bloccatoUser"
          :rules="[v => { return appMandatory ? !!v || 'Username obbligatorio' : true }]"
        />
        <v-text-field
          v-model="login.password"
          id="app_password"
          label="App password"
          name="app_password"
          prepend-icon="mdi-lock"
          :append-icon="show.app_password ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show.app_password ? 'text' : 'password'"
          counter
          clearable
          :rules="[v => { return appMandatory ? !!v || 'password obbligatoria' : true }]"
          @click:append="show.app_password = !show.app_password"
        />
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import { regInfoFromToken, regInfoFromClientId } from '@/utils/misc.js'
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        regType: 'dialog',
        app: {
          id: null,
          name: null,
          description: null,
          logo: null
        },
        login: {
          username: null,
          password: null,
          vcode: null
        }
      })
    },
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bloccato: false,
      bloccatoApp: false,
      bloccatoUser: false,
      bloccatoCode: false,
      show: {
        app_password: null
      },
      localAppsList: null,
      localApp: {
        regType: 'dialog',
        app: {
          id: null,
          name: 'vuota',
          description: null,
          logo: null
        },
        login: {
          username: null,
          password: null
        }
      }
    }
  },
  computed: {
    codeMandatory () {
      return this.mandatory && !(this.login.username)
    },
    appMandatory () {
      return this.mandatory && !(this.login.vcode)
    },
    showVCode () {
      return this.regType !== 'dialog' && !this.bloccatoCode
    },
    showMe () {
      return this.regType !== 'direct'
    },
    showBox () {
      return this.regType !== 'dialog'
    },
    regType: {
      get () {
        return this.value.regType || this.localApp.regType
      },
      set (regType) {
        this.$emit('input', { app: this.app, login: this.login, regType })
      }
    },
    app: {
      get () {
        return this.value.app || this.localApp.app
      },
      set (app) {
        if (app) this.$emit('input', { app, login: this.login, regType: this.regType })
      }
    },
    login: {
      get () {
        return this.value.login || this.localApp.login
      },
      set (login) {
        this.$emit('input', { app: this.app, login, regType: this.regType })
      }
    },
    appList () {
      if (!this.localAppsList) this.getApps()
      return this.localAppsList
    }
  },
  methods: {
    async getApps () {
      const apps = await this.$http.get('/accounts/apps/account/list_apps/')
      let app = null
      this.localAppsList = apps.data
      if (!this.mandatory) {
        this.localAppsList.unshift({
          id: null,
          name: 'Nessuna',
          description: 'Nessuna applicatione',
          logo: ''
          // logo: '/static/img/prohibition.png'
        })
      }
      console.log('Next', this.$route.query.next)
      if (this.$route.query.next) {
        app = await regInfoFromToken(this.$route.query)
        if (isEmpty(app)) {
          app = await regInfoFromClientId(this.$route.query)
        }
      }
      if (!isEmpty(app)) {
        console.log('App', app)
        this.regType = app.reg_type || this.regType
        this.login.username = app.username
        this.login.vcode = app.vcode
        this.bloccatoApp = true
        this.bloccatoUser = !!app.username
        this.bloccatoCode = !!app.vcode
        this.app = this.localAppsList.find(a => a.id === app.id)
        console.log('App locale', this.app)
        // this.app = app
      } else {
        this.app = this.localAppsList[0]
      }
    }
  }
}
</script>
