/**
 * module misc.js
 */
import axios from '@/plugins/axios.js'

const getRegisterToken = query => {
  if (query && query.next) {
    const next = decodeURIComponent(query.next)
    const [token] = next.split('?')
    if (token && token.includes('/accounts/register')) return token.split('/accounts/register/')[1].replace(/\/$/, '')
  }
  return null
}

const paramsFromNext = query => {
  if (!query.next) return {}
  const next = decodeURIComponent(query.next)
  const info = {
    params: {},
    fullPath: next
  }
  const [path, qstring] = next.split('?')
  if (qstring) {
    qstring.split('&').map(p => {
      const [k, v] = p.split('=')
      info.params[k] = v
      return { k: v }
    })
  }
  info.registerToken = getRegisterToken(query)
  info.path = path
  if (!('register_token' in info.params)) info.params.register_token = info.registerToken
  else if (!info.registerToken) info.registerToken = info.params.register_token || null
  info.reqType = path === '/o/authorize/' ? 'authorize' : /^\/accounts\/register\//.test(path) ? 'register' : 'unknow'
  return info
}
const regInfoFromToken = async query => {
  const token = paramsFromNext(query).registerToken
  if (!token) return {}
  const res = await axios.get(`/accounts/app_info_bt/${token}/`)
  return res.data
}
const regInfoFromClientId = async query => {
  const clientId = paramsFromNext(query).params.client_id
  if (!clientId) return {}
  const res = await axios.get(`/accounts/app_info_ci/${clientId}/`)
  return res.data
}
export {
  paramsFromNext,
  getRegisterToken,
  regInfoFromToken,
  regInfoFromClientId
}
