<template>
  <DialogCard
    title="Recupero account"
    :actions="actions"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <p>
        Inserire un'email o un numero di cellulare valido per l'account di {{ appName }}
      </p>
      <v-text-field
        v-model="accountRecovery.email"
        label="Email"
        :rules="[rules.email]"
        prepend-icon="mdi-email"
        clearable
      />
      <v-text-field
        v-model="accountRecovery.phone"
        label="Telefono"
        :rules="[rules.phoneNumber]"
        prepend-icon="mdi-phone"
        clearable
      />
    </v-form>
  </DialogCard>
</template>
<script>
import rules from '@/utils/rules.js'
import messages from '@/utils/messages'
export default {
  props: {
    appId: {
      type: undefined,
      default: null
    },
    appName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: null,
      rules,
      accountRecovery: {
        phone: '',
        email: ''
      }
    }
  },
  computed: {
    actions (vm) {
      return {
        false: {
          text: 'Annulla',
          outlined: true,
          color: 'primary'
        },
        true: {
          outlined: true,
          text: 'Invia',
          color: 'success',
          async handler () {
            const done = await vm.$http.get(
              `/accounts/apps/account/recover_account/${vm.appId}/`,
              { params: vm.accountRecovery })
              .then(r => {
                messages.info(r)
                return true
              })
              .catch(e => {
                messages.error(e)
                return false
              })
            return done
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .deleted-phone {
    text-decoration: line-through;
  }
</style>
